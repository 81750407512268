

.svg-container {
  position: relative;
  max-width: 350px;
  width: 50%;
  height: 100px;
}

.svg {
  width: 100%;
  height: auto;
}

/* Path animasyonları */
.pths {
  opacity: 0; /* Başlangıçta görünmez */
  animation: fadeIn 2s ease-in forwards; /* Animasyonun görünmesini sağlar */
}

/* Opacity animasyonu */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}