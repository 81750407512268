@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
 
.comming-soon {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('./looper.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-wrap: wrap;
  position: relative;
}

.a {
  width: 100%;
  flex: 1;
  text-align: center;
}

.image {
  width: 100%;
  max-width: 400px;
  flex: 1;
  margin-bottom: 30px;
  text-align: center;
}

.main-title {
  color: #5E53A2;
  font-weight: 700;
  font-size: 56px;
  text-align: center;
  margin: 0;
}

.text {
  margin-top: 25px;
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: #1F2B38;
  text-align: center;
}

.title {
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;
  color: #1F2B38;
  text-align: center;
}

.text-area {
  width: 75%;
  z-index: 1;
  display: grid;
  place-items: center;
  margin: auto;
}

.contacts {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 4rem;
}

.iletisime-gec {
  /* width: 113px; */
  height: 24px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  z-index: 1;
  text-decoration: none;
}

.on-basvuru-area, .iletisime-gec-area {
  /* width: 113px; */
  height: 36px;
  border-radius: 4px;
  padding: 6px 10px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .5s ease-in-out, transform .3s ease-in-out;
  cursor: pointer;
  z-index: 2;
  text-decoration: none;
}

.on-basvuru-area {
  background-color: #5E53A2;
}

.iletisime-gec-area {
  background-color: transparent;
}

.iletisime-gec-area:hover {
  background-color: #DDDDDD;
}

.on-basvuru-area:hover {
  background-color: #5e48f0;
  transform: scale(1.05);
}

.on-basvuru {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #fff;
  text-decoration: none;
}

.phones {
  position: absolute;
  bottom: 0;
  right: 20px;
}

.clipOne {
  bottom: 0;
  margin-right: 10rem;
  z-index: 0;
}

.clipTwo {
  position: absolute;
  bottom: 10px;
  right: 3.5rem;
  z-index: 1;
}

.footer-area {
  width: 100%;
  height: 53px;
  position: fixed;
  bottom: 2rem;
}

.social-network {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 27px;
}

.copyrigth {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

@media (max-width: 1160px) {
  .phones {
    position: relative;
    margin: 0 auto;
  }
  
  .clipOne {
    width: 85%;
    margin: 0 auto;
  }
  
  .clipTwo {
    width: 95%;
    right: -5rem;
  }
  
  .footer-area {
    margin: 0 auto;
  }
}

@media (max-width: 710px) {
  .text-area{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px;
  }
  .phones {
    width: 100%;
    margin-top: 35px;
  }
  
  .clipOne {
    width: 75%;
  }
  
  .clipTwo {
    width: 85%;
    right: 5px;
  }
  
  .footer-area {
    position: relative;
    margin: 0 auto;

    bottom: .5rem;
  }
}
