@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.input-container {
    height: 55px;
    width: 48%;
    min-width: 250px;
    position: relative;
    margin-bottom: 15px;
    border: 1px solid #ccc; 
    border-radius: 4px;  
    padding: 5px;  
}

.input-container label {
    position: absolute;
    top: 50%;
    left: 15px;  
    transform: translateY(-50%);
    transition: 0.3s ease all;
    pointer-events: none;
    color: #aaa;
}

.input-container input:focus + label,
.input-container input:not(:placeholder-shown) + label {
    top: 10px;
    left: 10px;
    font-size: 12px;
    color: #000;
}

.input-container input {
    padding: 10px;
    padding-left: 10px;
    background-color: transparent;
    border: none;  
    width: 100%;
    outline: none;  
    box-sizing: border-box;  
    position: absolute;
    bottom: 0;
    font-size: 20px; 
}

.input-container:focus-within {
    border-color: #000; 
    transition: border-color 0.3s ease;
}
.input-container input:focus {
    border-color: #000;  
    outline: none;  
}
.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.checkbox-container>input{
    transform: scale(1.5);
}
.checkbox-container p {
    margin: 0;
    font-size: 14px;
    margin-left: 5px;
}
.custom-checkbox:checked + label:before {
    background-color: #5E53A2;
    border-color: #5E53A2;
}
.checkbox-containe {
    content: "\2713";
    position: absolute;
    left: 3px;
    top: -2px;
    font-size: 16px;
    background-color: #5E53A2;
    color: #ff0000; /* Tik işaretinin rengini değiştirme */
}
.checkbox-container span {
    font-weight: bold;
    color: #5E53A2; 
} 
input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border: 2px solid #5e53a2;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    background-color: white;
    padding-right: 10px;
    margin-right: 10px;
}

/* Seçili durumda arka plan rengi */
input[type="checkbox"]:checked {
    background-color: #5e53a2;
    border-color: #5e53a2;
    position: relative;
}
input[type="checkbox"]:checked:after {
    content: "\2713"; /* Unicode tik işareti */
    position: absolute;
    top: -4px;
    left: 1px;
    font-size: 14px;
    color: white;

}
.pre-logo{
    width: 80%;
    padding: 2rem;
    text-align: center;
}
.pre-logo>img{
    max-width: 180px;
}
.pre-top{
    margin-top: 5rem;
    max-width: 923px;
    width: 80%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.pre-top-title{
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color:#5E53A2;
}
.pre-top-description{
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    margin-top: 20px;
}
.pre-form{
    width: 80%;
    max-width: 850px;
    display: flex;
    align-items: center; 
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 4rem;
    
}
.pre-info{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    flex-wrap: wrap;
    margin-top: 25px;
}
.pre-info-box{
    max-width: 337px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.pre-info-box-title{
    width: 100%;
    text-align: center;
    color: #1F2B38; 
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
}
.pre-info-box-description{
    width: 100%;
    text-align: center;
    color: #1F2B38;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
}
.pre-social-network{
    width: 100%;
    margin: 25px 0 25px 0;
}
.pre-footer{
    width: 100%;
    background-color: #fff;
    /* border: .5px solid; */
    box-shadow: 0 0 5px #979797;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.pre-footer-p{
    /* width: 250px; */
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1F2B38;
}
.pre-footer-a{
    width: 189px;
    height: 36px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    background-color: #5E53A2;
    color: #fff;
    margin-left: auto;
    margin-right: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    padding: 10px 10px 6px 10px ;
}
@media (max-width: 710px) {
    .pre-form{
        width: 80%;
        max-width: 850px;
        display: flex;
        align-items: center; 
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 4rem;
        
    }
    .pre-footer{
        justify-content: center;
    }
    .pre-footer-a{
        margin: 0;
        margin-top: 20px;
    }
}